/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current state for smart pay as you go
 */
export type SmartPayAsYouGoState =
  (typeof SmartPayAsYouGoState)[keyof typeof SmartPayAsYouGoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SmartPayAsYouGoState = {
  off: "off",
  on: "on",
  pending: "pending",
} as const
