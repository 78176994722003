<script setup lang="ts">
  import {
    AgreementsResponse,
    AgreementsResponseFuelType,
    SmartPayAsYouGoQuoteResponse,
  } from "@/api/model"
  import { getTariffRateTitle } from "@/lib/agreements"
  import { formatCurrency, formatCurrencyInPence } from "@/lib/utils"
  import { RateNames } from "@/types/RateNames"
  import dayjs from "dayjs"
  import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
  import { computed } from "vue"
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/components/ui/table"

  dayjs.extend(isSameOrAfter)

  type TableCellType = {
    text?: string
    class?: string
    colspan?: number
  }

  type TableRowType = {
    class?: string
    cells: TableCellType[]
  }

  const props = defineProps<{
    spaygQuote?: SmartPayAsYouGoQuoteResponse
    agreements?: AgreementsResponse[]
  }>()

  const commonTariffName = computed(() => {
    const tariffName = props.agreements?.[0]?.displayName

    if (
      tariffName &&
      props.agreements?.every(
        (agreement) => agreement.displayName === tariffName,
      )
    )
      return tariffName

    return undefined
  })

  const exitFeeTotal = computed(
    () =>
      props.agreements?.reduce(
        (prev, current) =>
          prev + (current.tariffEarlyTerminationChargeInPounds || 0),
        0,
      ) || 0,
  )

  const formatInPence = (value?: number | string, extra?: string) => {
    if (!value) return "--"

    value = typeof value === "string" ? parseInt(value) : value
    return `${formatCurrencyInPence(value)} ${extra || ""}`
  }

  const tableHeaders = [
    { text: "" },
    {
      text: "Current tariff",
      subheader: commonTariffName.value,
    },
    {
      text: "So Pay As You Go Tariff",
      subheader: "Top up as you need",
      class: "text-primary-600",
    },
  ]

  const getSpaygDataByFuel = (
    fuel: AgreementsResponseFuelType = AgreementsResponseFuelType.Electricity,
  ) => {
    if (fuel === AgreementsResponseFuelType.Electricity) {
      const tariff =
        props.spaygQuote?.elecRates?.find(
          (rate) => rate.name === RateNames.Standard,
        ) || props.spaygQuote?.elecRates?.[0]

      return {
        costPerKwhInPence: tariff?.costPerKwhInPence,
        standingChargeInPence: props.spaygQuote?.elecStandingChargeInPence,
        elecConsumptionPerYearInKwh:
          tariff?.eco7ElecConsumptionPerYearInKwh ??
          props.spaygQuote?.elecConsumptionPerYearInKwh,
      }
    }

    return {
      costPerKwhInPence: props.spaygQuote?.gasCostPerKwhInPence,
      standingChargeInPence: props.spaygQuote?.gasStandingChargeInPence,
      elecConsumptionPerYearInKwh: props.spaygQuote?.gasConsumptionPerYearInKwh,
    }
  }

  const formatAgreementRows = (
    agreement: AgreementsResponse,
  ): TableRowType[] => {
    const agreementTariffs = agreement.tariffRates ?? []
    const hasMultipleTariffs = agreementTariffs.length > 1
    const spaygDataByFuel = getSpaygDataByFuel(agreement.fuelType)

    return [
      {
        cells: [
          {
            text: agreement.fuelType || AgreementsResponseFuelType.Electricity,
            class: "font-medium",
          },
          { text: commonTariffName.value ? "" : agreement.displayName || "--" },
        ],
      },
      {
        cells: [
          { text: "Unit rate" },
          {
            text: hasMultipleTariffs
              ? ""
              : formatInPence(agreementTariffs[0]?.rateInPence, "per kWh"),
          },
          {
            text: formatInPence(spaygDataByFuel.costPerKwhInPence, "per kWh"),
          },
        ],
      },
      ...(hasMultipleTariffs
        ? agreementTariffs.flatMap((tariffRate) => ({
            cells: [
              { text: getTariffRateTitle(tariffRate) },
              {
                text: formatInPence(tariffRate.rateInPence, "per kWh"),
              },
              {
                text: "",
              },
            ],
          }))
        : []),
      {
        cells: [
          { text: "Daily standing charge" },
          { text: formatInPence(agreement.tariffStandingRateInPence) },
          {
            text: formatInPence(spaygDataByFuel.standingChargeInPence),
          },
        ],
      },
      {
        cells: [
          { text: "Estimated annual usage" },
          { text: "--" },
          {
            text: spaygDataByFuel.elecConsumptionPerYearInKwh,
          },
        ],
      },
    ]
  }

  const tableRows = computed<TableRowType[]>(() => {
    if (commonTariffName.value) {
      return (props.agreements || [])
        .flatMap<TableRowType>((agreement) => formatAgreementRows(agreement))
        .concat([
          {
            cells: [
              { text: "Exit fee", class: "font-medium" },
              {
                text: formatCurrency(exitFeeTotal.value ?? 0),
              },
              {
                text: formatCurrency(0),
              },
            ],
          },
        ])
    }

    return (props.agreements || []).flatMap<TableRowType>(
      (agreement, index) => {
        const isLastAgreement = index + 1 === props.agreements?.length

        return formatAgreementRows(agreement)
          .concat([
            {
              class: "border-b-0",
              cells: [
                { text: "Exit fee" },
                {
                  text: formatCurrency(
                    agreement.tariffEarlyTerminationChargeInPounds ?? 0,
                  ),
                },
                {
                  text: formatCurrency(0),
                },
              ],
            },
          ])
          .concat(
            !isLastAgreement
              ? [
                  {
                    class: "border-b-0 h-6 sm:h-8",
                    cells: [{ text: "", colspan: 3 }],
                  },
                ]
              : [],
          )
      },
    )
  })
</script>

<template>
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead
          v-for="(header, index) in tableHeaders"
          :key="index"
          :class="header.class"
        >
          {{ header.text }}
          <template v-if="header.subheader" #subheader>{{
            header.subheader
          }}</template>
        </TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow
        v-for="(row, rowIndex) in tableRows"
        :key="rowIndex"
        :class="row.class"
      >
        <TableCell
          v-for="(cell, cellIndex) in row.cells"
          :key="cellIndex"
          :class="cell.class"
          :colspan="cell.colspan"
          >{{ cell.text }}</TableCell
        >
      </TableRow>
    </TableBody>
  </Table>
</template>
