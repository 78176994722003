<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    AccordionItem,
    useForwardProps,
    type AccordionItemProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    AccordionItemProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <AccordionItem
    v-bind="forwardedProps"
    :class="cn('border-b', props.class)"
    data-testid="accordion-item"
  >
    <slot />
  </AccordionItem>
</template>
