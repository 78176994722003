<script setup lang="ts">
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { z } from "zod"
  import { Button } from "@/components/ui/button"
  import { Checkbox } from "@/components/ui/inputs/checkbox"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const formSchema = toTypedSchema(
    z.object({
      requirementsAuthorisation: z.literal<boolean>(true, {
        errorMap: () => ({
          message:
            "You must confirm that you've read and understood the above requirements to continue.",
        }),
      }),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })
  const { goNext } = useStepWizard()
  const onSubmit = handleSubmit(() => {
    goNext()
  })
</script>

<template>
  <StepWizardProgressCard title="Is Smart Pay As You Go Right for you?">
    <div class="mb-6 space-y-8">
      <p>
        Thinking of switching to So Energy's Smart Pay As You Go? Here's what
        you need to know before you sign up.
      </p>

      <p>
        This service helps you manage energy use via an In-Home Display and the
        So Energy Top-Up App, allowing real-time top-ups without needing a card
        or key. However, occasional issues may require manual top-up code entry,
        so ensure easy access to your display or meter.
      </p>

      <p>
        If you run out of credit, you won't lose supply from 4pm-10am
        (Mon-Thurs) or 4pm Fri-10am Mon, as well as on public holidays. However,
        energy used during these times must be repaid with your next top-up.
      </p>

      <p>
        Emergency credit is available as a short-term solution when your balance
        is low. Meter updates may temporarily prevent top-ups, requiring
        emergency credit activation if needed.
      </p>

      <div>
        <p>We don't recommend this service if you or someone in your home:</p>

        <ul class="list-disc px-6">
          <li>Is elderly, a young child, or relies on medical equipment</li>
          <li>Can't top up via two different methods</li>
          <li>
            Requires refrigerated medication or emergency contact services
          </li>
        </ul>
      </div>

      <p>
        Switching back to credit mode may require a credit check or deposit.
      </p>

      <p>
        If you understand the service limitations and are happy to go ahead,
        please check the box and click next.
      </p>
    </div>
    <form>
      <Checkbox
        label="I confirm that I've read and understood the above requirements"
        name="requirementsAuthorisation"
      />
    </form>
    <template #next_button>
      <Button variant="tonal" size="md" @click="onSubmit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
