<script setup lang="ts">
  import { useGetAccountStates } from "@/api/account-state/account-state"
  import { GetUnbilledQueryError, useGetUnbilled } from "@/api/account/account"
  import { BillGenerationState } from "@/api/model/billGenerationState"
  import { ErrorCode } from "@/api/model/errorCode"
  import { SupplyState } from "@/api/model/supplyState"
  import { Route } from "@/constants/route.ts"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import ErrorReporter from "@/services/ErrorReporter"
  import dayjs from "dayjs"
  import { TooltipArrow } from "radix-vue"
  import { computed } from "vue"
  import { Button } from "@/components/ui/button"
  import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import Icon from "@/components/ui/icons/Icon.vue"
  import { Link } from "@/components/ui/link"
  import PlainTooltip from "@/components/ui/plain-tooltip/PlainTooltip.vue"
  import Popover from "@/components/ui/popover/Popover.vue"
  import PopoverContent from "@/components/ui/popover/PopoverContent.vue"
  import PopoverTrigger from "@/components/ui/popover/PopoverTrigger.vue"

  import EnergyUsageCardSkeleton from "./EnergyUsageCardSkeleton.vue"

  const pendingBillProcessingWindow = 4 // days
  const blank = "--.--"
  const accountIdQuery = useGetAccountId()
  const unbilledQuery = useGetUnbilled(
    accountIdQuery.accountId,
    { includeStandingCharges: false },
    {
      query: {
        throwOnError: (error: GetUnbilledQueryError) => {
          const errorCode = error?.response?.data?.errorCode
          const isExpectedServerError = errorCode === ("notFound" as ErrorCode)
          if (!isExpectedServerError) ErrorReporter.report(error)
          return false
        },
      },
    },
  )
  const accountStateQuery = useGetAccountStates(accountIdQuery.accountId)

  const { isError, isSuccess, isFetching, isLoading } = aggregateQueries([
    accountIdQuery,
    unbilledQuery,
    accountStateQuery,
  ])

  const summary = computed(() => {
    const nextBill = unbilledQuery.data?.value?.nextBill
    const isElecEstimated = Boolean(nextBill?.electricityUsage.isEstimated)
    const isGasEstimated = Boolean(nextBill?.gasUsage.isEstimated)
    const isEstimated = isElecEstimated || isGasEstimated

    const formatConsumption = (
      consumption: number | undefined,
      isEstimated: boolean,
    ) => {
      if (!consumption) return blank
      return isEstimated ? consumption.toFixed(0) : consumption.toFixed(2)
    }

    return {
      dateRange: nextBill
        ? `${dayjs(nextBill.startDate).format("D MMM")} - Today`
        : "",
      totalCostInPounds: nextBill?.totalCostInPounds.toFixed(2) ?? blank,
      totalConsumptionInKwh: formatConsumption(
        nextBill?.totalConsumptionInKwh,
        isEstimated,
      ),
      electricityUsage: {
        isSupplied: nextBill?.electricityUsage.isSupplied,
        usageCostInPounds:
          nextBill?.electricityUsage.usageCostInPounds.toFixed(2),
        consumptionInKwh: formatConsumption(
          nextBill?.electricityUsage.consumptionInKwh,
          isElecEstimated,
        ),
      },
      gasUsage: {
        isSupplied: nextBill?.gasUsage.isSupplied,
        usageCostInPounds: nextBill?.gasUsage.usageCostInPounds.toFixed(2),
        consumptionInKwh: formatConsumption(
          nextBill?.gasUsage.consumptionInKwh,
          isGasEstimated,
        ),
      },
      isEstimated,
    }
  })

  const estimatedCopy = computed(() =>
    summary.value.isEstimated
      ? "We've calculated your estimated usage for this period using your previous meter reads."
      : "We've calculated your up to date usage from your last bill until today.",
  )

  const nextBillIsBeingProcessed = computed(() => {
    const pendingBill = unbilledQuery.data.value?.pendingBill
    if (pendingBill) {
      const endDate = dayjs(pendingBill.endDate)
      return endDate.isAfter(
        dayjs(new Date()).subtract(pendingBillProcessingWindow, "days"),
      )
    }
    return false
  })

  const unableToGenerateEstimate = computed(() => {
    const nextBill = unbilledQuery.data?.value?.nextBill

    const unableToEstimateSuppliedElectricityUsage =
      nextBill?.electricityUsage.unableToEstimate
    const unableToEstimateSuppliedGasUsage = nextBill?.gasUsage.unableToEstimate
    return (
      accountStateQuery.data?.value?.billGeneration ===
        BillGenerationState.noBillNewAccount ||
      unableToEstimateSuppliedElectricityUsage ||
      unableToEstimateSuppliedGasUsage
    )
  })

  const issueWithBillsNoEstimate = computed(() => {
    const nextBill = unbilledQuery.data?.value?.nextBill
    const hasNoNextBill = !nextBill
    const noBillWithin31Days =
      accountStateQuery.data?.value?.billGeneration ===
      BillGenerationState.noBillWithin31Days
    return hasNoNextBill || noBillWithin31Days
  })

  const isOnboarding = computed(() => {
    return (
      accountStateQuery.data?.value?.supply === SupplyState.onboarding ||
      accountStateQuery.data?.value?.supply === SupplyState.preOnboarding
    )
  })

  const willHaveFinalBill = computed(() => {
    return (
      accountStateQuery.data?.value?.supply === SupplyState.lossComplete ||
      accountStateQuery.data?.value?.supply === SupplyState.movedOut
    )
  })

  const isNotOnSupply = computed(() => {
    return (
      summary.value.electricityUsage.isSupplied === false &&
      summary.value.gasUsage.isSupplied === false
    )
  })
</script>

<template>
  <EnergyUsageCardSkeleton v-if="isLoading" />
  <Card v-else data-testid="your-usage-card">
    <CardHeader>
      <CardTitle>Energy Usage</CardTitle>
    </CardHeader>
    <CardContent>
      <p
        v-if="
          isError &&
          !isFetching &&
          accountStateQuery.data?.value?.billGeneration !==
            BillGenerationState.noBillNewAccount
        "
        class="body-text-strong inline-flex items-center"
      >
        <Icon name="Warning" size="lg" class="mr-1" />
        <span> Failed to load </span>
      </p>
      <div v-if="isNotOnSupply">
        <p>
          Recent energy usage is unavailable as your meters are not currently on
          supply.
        </p>
      </div>
      <div v-else-if="isOnboarding">
        <p>
          We'll show your latest usage here as soon as we have enough readings
          from your meter.
        </p>
      </div>
      <div v-else-if="willHaveFinalBill">
        <p>
          You'll soon receive your final bill where you can view your usage.
        </p>
      </div>
      <div v-else-if="nextBillIsBeingProcessed">
        Your next bill is being processed. You'll receive it in your inbox
        within the coming days.
      </div>
      <div v-else-if="unableToGenerateEstimate || issueWithBillsNoEstimate">
        <p v-if="unableToGenerateEstimate">
          It looks like we don't have enough reads to generate an estimate.
          Submit regular reads to better understand your energy usage.
        </p>
        <p v-else>
          We can’t calculate your recent energy usage just yet as we’re
          currently updating your bills.
        </p>
        <div class="space-y-0.5">
          <p class="body-text-strong mb-1 mt-4 sm:mt-2">Total</p>
          <p>£{{ blank }} | {{ blank }} kWh</p>
        </div>
      </div>

      <div v-else>
        <div class="space-y-0.5">
          <p class="body-text-strong">
            Total ({{ summary.dateRange }})

            <span class="no-hover:hidden">
              <PlainTooltip>
                {{ estimatedCopy }}
              </PlainTooltip>
            </span>
            <span class="has-hover:hidden">
              <Popover>
                <template #default>
                  <PopoverTrigger as-child>
                    <Icon name="Info" class="mb-1.5 ml-0.5 inline-flex" />
                  </PopoverTrigger>
                  <PopoverContent
                    class="label-text w-52 rounded text-center shadow-md"
                    side="top"
                  >
                    {{ estimatedCopy }}

                    <TooltipArrow
                      :width="25"
                      :height="9"
                      style="transform: translateY(-1.5px)"
                      data-testid="plain-tooltip-arrow"
                    >
                      <svg viewBox="0 0 25 9">
                        <path
                          d="M0 1 25 1"
                          class="stroke-secondary-100 dark:stroke-secondary-600"
                        />
                        <path
                          d="M0 1C5.5 1 8.5 5 8.5 5C8.5 5 10.5 8 12.5 8C14.5 8 16.5 5 16.5 5C16.5 5 19.5 1 25 1"
                          class="fill-secondary-100 stroke-border dark:fill-secondary-600 dark:stroke-none"
                        />
                      </svg>
                    </TooltipArrow>
                  </PopoverContent>
                </template>
              </Popover>
            </span>
          </p>

          <p
            v-if="summary.isEstimated"
            class="body-text-strong text-secondary-500"
          >
            Estimated Usage
          </p>
          <p>
            {{
              `${summary.totalConsumptionInKwh} kWh | £${summary.totalCostInPounds}`
            }}
          </p>
        </div>

        <div class="my-4 flex">
          <div v-if="summary.electricityUsage.isSupplied" class="w-full">
            <dl class="relative pl-5">
              <Icon
                name="Electricity"
                class="absolute left-0 top-1 row-span-3 stroke-icon-primary sm:top-1.5"
              />
              <dt class="body-text-strong">Electricity</dt>
              <dl data-testid="next-bill-electricity-consumption-in-kwh">
                {{ `${summary.electricityUsage.consumptionInKwh} kWh` }}
              </dl>
              <dl data-testid="next-bill-electricity-usage-cost-in-pounds">
                £{{ summary.electricityUsage.usageCostInPounds }}
              </dl>
            </dl>
          </div>
          <div v-if="summary.gasUsage.isSupplied" class="w-full">
            <dl class="relative pl-5">
              <Icon
                name="Gas"
                class="absolute left-0 top-1 row-span-3 stroke-icon-secondary sm:top-1.5"
              />
              <dt class="body-text-strong">Gas</dt>
              <dl data-testid="next-bill-gas-consumption-in-kwh">
                {{ `${summary.gasUsage.consumptionInKwh} kWh` }}
              </dl>
              <dl data-testid="next-bill-gas-usage-cost-in-pounds">
                £{{ summary.gasUsage.usageCostInPounds }}
              </dl>
            </dl>
          </div>
        </div>
        <p class="label-text">
          All rates incl. VAT, excluding standing charges.
        </p>
      </div>
    </CardContent>
    <CardFooter>
      <Button variant="tonal" :as-child="isSuccess">
        <span v-if="!isSuccess">View Energy Usage</span>
        <Link v-else :to="Route.usage">View Energy Usage</Link>
      </Button>
    </CardFooter>
  </Card>
</template>
